import type { FileCategory as FileCategoryRaw } from './generated/components/FileCategory'
import type { IssueFileCategories as IssueFileCategoriesRaw } from './generated/components/IssueFileCategories'
import type { CompanyFileCategories as CompanyFileCategoriesRaw } from './generated/components/CompanyFileCategories'
import type { Company as CompanyRaw } from './generated/Company'
import type { FinancialStatements as FinancialStatementsRaw } from './generated/components/FinancialStatements'
import type { InterestRate as InterestRateRaw } from './generated/components/InterestRate'
import type { Homepage as HomepageRaw } from './generated/Homepage'
import type { FileList as FileListItemRaw } from './generated/components/FileList'
import type { Return as ReturnRaw } from './generated/components/Return'
import type { Issue as IssueRaw } from './generated/Issue'
import type { Footer as FooterRaw } from './generated/Footer'
import type { BusinessCategory as BusinessCategoryRaw } from './generated/BusinessCategory'

export type { Benefit } from './generated/components/Benefit'
export { type Collateral } from './generated/components/Collateral'
export type Issue = IssueRaw['attributes'] & {
  publishedAt?: Date | null
}

export type ResponseIssue = IssueRaw
export type Homepage = HomepageRaw['attributes']
export type HomepageBanner = Homepage['banner']
export type HomepageBenefits = Homepage['benefits']
export type Company = CompanyRaw['attributes']
export type FinancialStatements = FinancialStatementsRaw
export type InterestRate = InterestRateRaw
export type Return = ReturnRaw
export type Footer = FooterRaw['attributes']
export type BusinessCategory = BusinessCategoryRaw['attributes']

export type MailisearchBusinessCategory = Omit<BusinessCategory, 'icon'> & {
  icon: BusinessCategory['icon']['data']['attributes']
}

export type MailisearchIssue = Omit<IssueRaw['attributes'], 'company'> & {
  company: Omit<Company, 'businessCategory'> & {
    id: number
    businessCategory: MailisearchBusinessCategory
  }
}

export type ApiResponseMeta = {
  pagination?: {
    page: number
    pageSize: number
    pageCount: number
    total: number
  }
}

export type ApiResponseData<T> = {
  data: T
  meta?: ApiResponseMeta
}

export type WithId<T> = T & { id: number }

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type FileListItem = FileListItemRaw
export type FileCategory = FileCategoryRaw
export type IssueFileCategories = IssueFileCategoriesRaw
export type CompanyFileCategories = CompanyFileCategoriesRaw

export type Row = {
  key: string
  value: string
}
