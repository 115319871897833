import type { PaginationByPage } from '@nuxtjs/strapi'
import type {
  IssueSortKeys,
  IssuesCollateralType,
  ShortIssueWithId,
} from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { SortDirection } from '~/api/types'
import type { IssueCommercialBanner } from '~/api/types/generated/components/IssueCommercialBanner'

export type IssueSort = {
  direction: SortDirection
  active: boolean
}

export enum IssuesTypes {
  issues = 'issues',
  onlyOurIssues = 'onlyOurIssues',
  archivedIssues = 'archivedIssues',
  allIssues = 'allIssues',
  issuesForCompare = 'issuesForCompare',
}

export type IssueWithLoadingState = {
  issue?: ShortIssueWithId
  banner?: IssueCommercialBanner
  loading: boolean
  id: number | string
}

export enum ReturnsPeriodicityEnum {
  Monthly = 'Měsíčně',
  Quarterly = 'Čtvrtletně',
  HalfYearly = 'Půlročně',
  Yearly = 'Ročně',
  OneTime = 'Jednorázově',
}

export enum IssuesFiltersKeys {
  AnnualInterestRate = 'annualInterestRate',
  MfcrScore = 'mfcrScore',
  IssueMaturity = 'issueMaturity',
  CollateralTypes = 'collateralTypes',
  HasEarlyRepayment = 'hasEarlyRepayment',
  IsProspectusApprovedByCNB = 'isProspectusApprovedByCNB',
  StateBonds = 'stateBonds',
  OnlyOur = 'onlyOur',
  CanBeBoughtOnline = 'canBeBoughtOnline',
  DmatChecks = 'dmatChecks',
  BusinessCategory = 'businessCategory',
}

export type IssuesStoreSort = {
  [key in IssueSortKeys]: IssueSort
}

export type IssuesStoreTotalCount = { current: number, max: number } | undefined

export type ExtraIssuesListFilters = {
  excludedId?: number[]
  excludedCompanyIds?: number[]
  isArchived?: boolean
  isNotArchived?: boolean
  isAvailable?: boolean
  almostBought?: boolean
  onlyOur: boolean
}

export type RangeValue = [number, number]

export type IssuesListFilters = {
  [IssuesFiltersKeys.AnnualInterestRate]: RangeValue | null
  [IssuesFiltersKeys.MfcrScore]: RangeValue | null
  [IssuesFiltersKeys.DmatChecks]: number | null
  [IssuesFiltersKeys.IssueMaturity]: RangeValue | null
  [IssuesFiltersKeys.CollateralTypes]: IssuesCollateralType[]
  [IssuesFiltersKeys.HasEarlyRepayment]: boolean
  [IssuesFiltersKeys.IsProspectusApprovedByCNB]: boolean
  [IssuesFiltersKeys.CanBeBoughtOnline]: boolean
  [IssuesFiltersKeys.StateBonds]: boolean
  [IssuesFiltersKeys.BusinessCategory]: number[]

  notEqualSlug: string
} & ExtraIssuesListFilters

export type IssuesListStore = {
  issues: IssueWithLoadingState[]
  pagination: PaginationByPage
  loading: number
  pending: number
  totalCount: IssuesStoreTotalCount
  initialCount: number
}

export type IssuesListsState = Record<IssuesTypes, IssuesListStore>

export type IssuesStoreState = {
  _filters: Partial<IssuesListFilters>
  sort: IssuesStoreSort
  maxAnnualInterestRate: number
  mobileFiltersOpened: boolean
  search: string
  banner?: IssueCommercialBanner | null
  totalCount: IssuesStoreTotalCount
  prefetchedIssuesCount: number
} & IssuesListsState

export type ActiveSort = [IssueSortKeys, IssueSort]

export enum DetailsPageSections {
  Tagline = 'tagline',
  Title = 'title',
  Video = 'video',
  MainParams = 'mainParams',
  InvestedChart = 'investedChart',
  Warnings = 'warnings',
  MobileCalculator = 'mobileCalculator',
  ContactCard = 'contactCard',
  ReasonsToInvest = 'reasonsToInvest',
  MoneyUsagePlan = 'moneyUsagePlan',
  ReturnCalendar = 'returnCalendar',
  CompanyEconomicResults = 'companyEconomicResults',
  Parameters = 'parameters',
  RiskRating = 'riskRating',
  Collaterals = 'collaterals',
  InvestorPromo = 'investorPromo',
  ShowMoreIssues = 'showMoreIssues',
  ShowMoreIssuerIssues = 'showMoreIssuerIssues',
  CompanyDetails = 'companyDetails',
  InvestmentExample = 'investmentExample',
  Documents = 'documents',
  AdvertisingMessage = 'advertisingMessage',
  NotCompletedIssue = 'notCompletedIssue',
  BlogPosts = 'blogPosts',
  News = 'news',
}
